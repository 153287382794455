<template>
  <div>

    <!-- Filter Section -->
    <b-card title="Filters" class="mb-0">
      <b-form @submit.prevent>
        <b-row>
          <!-- Year -->
          <b-col cols="12" md="3">
            <b-form-group
              :label="$t('globalSingular.year')"
              label-for="year"
            >
              <v-select
                  id="year"
                  class="select-size-sm"
                  ref="firstFocusSelectInput"
                  v-model="form.year"
                  :options="LOV.yearLov"
                />
            </b-form-group>
          </b-col>

          <!-- month -->
          <b-col cols="12" md="3">
            <b-form-group
              :label="$t('globalSingular.month')"
              label-for="month"
            >
               <v-select
                  id="month"
                  class="select-size-sm"
                  ref="firstFocusSelectInput"
                  v-model="form.month"
                  :reduce="field => field.value"
                  :options="LOV.monthLov"
                />
            </b-form-group>
          </b-col>
    
          <!-- Cash Bank Code -->
          <b-col cols="12" md="3">
            <b-form-group
              :label="$t('apps.masterDataCashbank.moduleName')"
              label-for="cashbank-code"
            >
              <v-select
                id="cashbank-account"
                class="select-size-sm"
                ref="firstFocusSelectInput"
                v-model="form.cashbankId"
                :options="LOV.cashbankCodeAccounts"
                :reduce="field => field.id"
                label="label"
              />
            </b-form-group>
          </b-col>

          <!-- Cabang -->
          <b-col cols="12" md="3">
            <b-form-group
              :label="$t('apps.reportCashBank.singular.cabang')"
              label-for="cabang"
            >
              <v-select
                id="cabang"
                class="select-size-sm"
                label="brand"
                v-model="form.cabang"
                :options="LOV.cabangLOV"
                :reduce="field => field.id"
              />
            </b-form-group>
          </b-col>

          <!-- Button Filter -->
          <b-col cols="12" md="3">
            <b-button block variant="primary" size="sm" @click="getReport" class="mt-2 pd-1">
              {{ $t('globalActions.find') }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>

    <div class="mt-1 mb-1 d-flex justify-content-end">

      <b-button variant="outline-primary" @click="print">
        <feather-icon icon="FileIcon" />
        {{ $t('globalActions.print') }}
      </b-button>

      <b-button-group class="ml-1">
        <b-dropdown right variant="outline-primary">

          <template #button-content>
            <feather-icon icon="DownloadIcon"/>
            {{ $t('globalActions.export') }}
          </template>

          <!-- <b-dropdown-item-button @click="exportToPDF">PDF</b-dropdown-item-button> -->
          <b-dropdown-item-button @click="exportToExcel">Excel</b-dropdown-item-button>

        </b-dropdown>
      </b-button-group>

    </div>
    <!-- Table Container Card -->
    <b-card no-body id="pdfDom">
       <b-card-body class="text-center">
        <b-card-title>{{ companyName }}</b-card-title>
        <b-card-sub-title>
          <div>{{ $t('apps.reportCasAndhBank.singular.cash&bankSummary') }}</div>
          <div class="mt-sm-1">{{ periodReportText }}</div>
        </b-card-sub-title>
      </b-card-body>
      <!-- looping table  -->
      <div v-for="(item, index) in itemLists" :key="index">
        <div id="tables">
          <div class="invoice-number-date mt-md-0 mt-2">
            <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
              <table>
                  <tbody>
                    <tr>
                      <td class="pr-1">
                        Branch 
                      </td>
                      <td><span class="font-weight-bold"> : {{itemLists[index].header.branch}}</span></td>
                    </tr>
                  </tbody>
                </table>
            </div>
          </div>
          <b-table
              id="refListTable"
              ref="refListTable"
              no-provider-paging
              no-provider-filtering
              :filter-included-fields="columnToBeFilter"
              :fields="tableColumns"
              :items="itemLists[index].details"
              :filter="searchQuery"
              responsive
              primary-key="id"
              show-empty
              :busy="isBusy"
              empty-text="No matching records found"
              :sort-desc.sync="isSortDirDesc"
              class="position-relative"
          >
              <template #table-busy>
                <div class="my-2 text-center text-primary d-flex flex-column justify-content-center">
                  <div class="mb-1 text">
                    <b-spinner class="align-middle"/>
                  </div>
                  <strong>{{ $t('globalActions.fetchingDataAPI') }}</strong>
                </div>
              </template>

            <!-- Column: cashbank -->
            <template #cell(cashbank)="{ item }">
                <div v-if="(item.type !== 'Bank') && (item.type !== 'Cash') && (item.type !== 'Total ALL')">
                  <span class="Item">
                   <i> <b>{{ item.cashbank }} </b> </i>
                  </span>
                </div>
                <div v-else-if="item.type === 'Total ALL'">
                  <span class="">
                   <!-- <i> <b> </b> </i> -->
                  </span>
                </div>
                <div v-else>
                  <span id="child-account">
                   {{ item.cashbank }}
                  </span>
                </div>
            </template>

            <template #cell(total)="{ item }">
                  <div v-if="item.type === 'Total ALL' ">
                      <span class="total-top">
                       <i> <b>Total Cash and Bank </b> </i>
                      </span>
                  </div>
            </template>

            <!-- Column: cashbank -->
            <template #cell(amount)="{ item }">
              <div v-if="(item.type !== 'Bank') && (item.type !== 'Cash')"> 
                    <div v-if="item.amount < 0">
                      <div v-if="item.type === 'Total ALL' ">
                        <span class="font-weight-bolder d-block text-nowrap text-danger">
                          <i> <b>({{ formatCurrency(item.amount * -1) }}) </b> </i>
                        </span>
                      </div>
                      <div v-else>
                        <span class="font-weight-bolder d-block text-nowrap text-danger">
                          <i> <b>({{ formatCurrency(item.amount * -1) }}) </b> </i>
                        </span>
                      </div>
                    </div>
                    <div v-else>
                      <span>
                        <i> <b> {{ formatCurrency(item.amount) }} </b> </i>
                      </span>
                    </div>
                </div>
                <div v-else>
                    <div v-if="item.amount < 0">
                        <span class="font-weight-bolder d-block text-nowrap text-danger">
                        ({{ formatCurrency(item.amount * -1) }})
                        </span>
                    </div>
                    <div v-else>
                        <span>
                        {{ formatCurrency(item.amount) }}
                        </span>
                    </div>
                </div>
            </template>
          </b-table>
        </div>
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BButtonGroup,
  BButton,
  BDropdown,
  BDropdownItemButton,
  BTable
//   BSpinner
} from 'bootstrap-vue'

import { ref, onMounted } from '@vue/composition-api'
import { formatCurrency } from '@/utils/formatter'
import vSelect from 'vue-select'
import useListTable from '@/comp-functions/useListTable'
import useFormResource from '@/comp-functions/useFormResource'
import storestate from '@/store/index'
import { getYears } from '@/utils'
import moment from 'moment'


export default {
  components: {
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BButtonGroup,
    BButton,
    BDropdown,
    BDropdownItemButton,
    BTable,
    // BSpinner,
    vSelect,
    // flatPickr
  },
  computed: {
    companyName () {
      return this.$store.state.user.currentCompany.name
    }
  },
  setup () {
    const form = ref({
      year: moment().year(),
      month: null,
      cashbankId: '',
      cabang: ''
    })

    const periodReportText = ref()

    // export format
    const formats    = ['xlsx', 'csv', 'txt']
    const dateNow    = new Date();
    const getMonth   = dateNow.getMonth() + 1 
    form.value.month = getMonth



    const LOV = ref({
      period: [
        { value: 'thisMonth', label: 'This Month' },
        { value: 'previousMonth', label: 'Previous Month' },
        { value: 'thisYear', label: 'This Year' },
        { value: 'previousYear', label: 'Previous Year' }
      ],
      yearLov: getYears(),
    monthLov: [
                  {
                    label: 'January',
                    value: 1
                  },
                  {
                    label: 'February',
                    value: 2
                  },
                  {
                    label: 'March',
                    value: 3
                  },
                  {
                    label: 'April',
                    value: 4
                  },
                  {
                    label: 'May',
                    value: 5
                  },
                  {
                    label: 'June',
                    value: 6
                  },
                  {
                    label: 'July',
                    value: 7
                  },
                  {
                    label: 'August',
                    value: 8
                  },
                  {
                    label: 'September',
                    value: 9
                  },
                  {
                    label: 'October',
                    value: 10
                  },
                  {
                    label: 'November',
                    value: 11
                  },
                  {
                    label: 'December',
                    value: 12
                  }
              ],
      cabangLOV: [],
      cashbankAccounts: [],
      cashbankCodeAccounts: [],
    })

    // Table Handlers
    const tableColumns = [
      { key: 'cashbank', label: 'Cash & Bank', thClass:'', tdClass: '' },
      { key: 'total', label: '', thClass:'text-right', tdClass: 'text-right' },
      { key: 'amount', label: 'Amount', thClass: 'text-right', tdClass: 'text-right' }
    ]

    // Table Handlers
    const columnToBeFilter = [
      'code',
      'date',
      'reference_code'
    ]



    const updatePeriodText = () => {
      let titleMonth = form.value.month
      const titleYear    = form.value.year

      if(titleMonth === 1){
         titleMonth = 'January'
      }if(titleMonth === 2){
         titleMonth = 'February'
      }if(titleMonth === 3){
         titleMonth = 'March'
      }if(titleMonth === 4){
         titleMonth = 'April'
      }if(titleMonth === 5){
         titleMonth = 'May'
      }if(titleMonth === 6){
         titleMonth = 'June'
      }if(titleMonth === 7){
         titleMonth = 'July'
      }if(titleMonth === 8){
         titleMonth = 'August'
      }if(titleMonth === 9){
         titleMonth = 'September'
      }if(titleMonth === 10){
         titleMonth = 'October'
      }if(titleMonth === 11){
         titleMonth = 'November'
      }if(titleMonth === 12){
         titleMonth = 'December'
      }

      periodReportText.value = titleMonth + ' ' + titleYear
    }

    const companyActive = storestate.state.user.currentCompany

    const {
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      isSortDirDesc,
      refListTable,
      statusFilter,
      fetchLists
    } = useListTable({ url: false })

    const { get } = useFormResource({ url: '' })

    const getCashbankAccount = async () => {
      LOV.value.cashbankAccounts = await get({ url: 'master/accounts/child?account_subtype_code=CASHBANK'})
    }

    const cabangLov = async () => {
      LOV.value.cabangLOV = await get({ url: 'value/getOffice'})
    }


    const getCashbankCode = async () => {
      LOV.value.cashbankCodeAccounts = await get({ url: 'value/mapping-cashbank-per-users'})
    }

    onMounted(async () => {
      if(!storestate.state.user.is_national){
        form.value.cabang = storestate.state.user.companies[0].office.id
      }
      getCashbankAccount()
      cabangLov()

      if (companyActive.plan_id > 2) {
        getCashbankCode()
      }
      let monthYear = form.value.month + '/' + form.value.year
      const params = [
        `monthYear=${monthYear}`,
        `cabang=${form.value.cabang ?? ''}`,
        `cashbankId=${form.value.cashbankId}`
      ]

      const url = `reports/cashbankAndBankBranch?${ params.join('&') }`
      await fetchLists(url)
      updatePeriodText()
    })

    const stylingCOA = (isHeader, tdClass, idx) => {
        console.log(idx)
      return {
        [tdClass]: true,
        'header-category-account': isHeader === true,
        'child-account': isHeader === false,
      }
    }


    return {
      form,
      periodReportText,
      formats,
      LOV,
      // dateFormat,
      tableColumns,
      columnToBeFilter,
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      isSortDirDesc,
      refListTable,
      statusFilter,
      companyActive,
      fetchLists,
      updatePeriodText,
      formatCurrency,
      stylingCOA
    }
  },
  methods: {
    getReport () {
      let monthYear = this.form.month + '/' + this.form.year
      const params = [
        `monthYear=${monthYear}`,
        `cabang=${this.form.cabang ?? ''}`,
        `cashbankId=${this.form.cashbankId}`
      ]

      const url = `reports/cashbankAndBankBranch?${ params.join('&') }`
      this.fetchLists(url)
      this.updatePeriodText()
    },
    print () {
      window.print()
    },
    exportToPDF () {
      this.$nextTick(() => this.getPdf())
    },
    exportToExcel () {
      import('@/vendors/Export2Excel').then(excel => {
        const list  = this.itemLists
        let dataFix = []
        // console.log(list)
        for (let index = 0; index < list.length; index++) {
          const dataHeader1 = {
            total: list[index].header.branch,
            cashbank: '',
            amount: ''
          }
            dataFix.push (dataHeader1)

          for (let i = 0; i < list[index].details.length; i++) {
            dataFix.push (list[index].details[i])
          }
        }

        const headerTitle = this.tableColumns.map(column => column.label)
        // only take indexData from columnDef,
        const headerVal = this.tableColumns.map(column => column.key)
        console.log('----->>',headerVal)
        const data = this.formatJson(headerVal, dataFix)
        excel.export_json_to_excel({
          multiHeader: [
            [this.$store.state.user.currentCompany.name, '', '', '', '', '', ''],
            [this.$t('apps.reportCashBank.singular.cashBankReport')+ ' Summary', '', '', '', '', '', ''],
            [this.periodReportText, '', '', '', '', '', ''],
            ['', '', '', '', '', '', '']
          ],
          header: headerTitle,
          data,
          merges: [
            { s: { c: 0, r: 0 }, e: { c: 5, r: 0 } },
            { s: { c: 0, r: 1 }, e: { c: 5, r: 1 } },
            { s: { c: 0, r: 2 }, e: { c: 5, r: 2 } },
            { s: { c: 0, r: 3 }, e: { c: 5, r: 3 } }
          ],
          filename: `${this.$t('apps.reportCashBank.singular.cashBankReport')} ${this.periodReportText}`,
          autoWidth: true,
          bookType: 'xlsx'
        })
      })
    },
    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        return v[j]
      }))
    }
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';

  #child-account {
    padding-left: 48px !important;
    font-size: 0.9rem;
  }
.total-top-category-account {
    font-weight: 600;
    font-size: 1rem;
    font-style: italic;
  }
    #tables {
    width: inherit;
    margin: 0 160px 10px 160px;
    padding: 20px 0;

    thead {
      th {
        border-top: 2px solid #ebe9f1;
      }
      th:first-child {
        border-left: 2px solid #ebe9f1;
        border-radius: 4px;
      }

      th:last-child {
        border-right: 2px solid #ebe9f1;
        border-radius: 4px;
      }
    }

    td:first-child {
      border-left: 2px solid #ebe9f1;
    }
    td:last-child {
      border-right: 2px solid #ebe9f1;
    }

    tbody {
      tr:last-child {
        border-bottom: 2px solid #ebe9f1;
      }
    }

  }

  .header-category-account {
    font-weight: 600;
    font-size: 1rem;
    font-style: italic;

  }

  .dropdown-menu .dropdown-item {
    width: 100%;
  }

  #cash-and-bank {
    width: inherit;
    margin: 0 160px 20px 160px;
    padding: 20px 0;

    thead {
      th {
        border-top: 2px solid #ebe9f1;
      }
      th:first-child {
        border-left: 2px solid #ebe9f1;
        border-radius: 4px;
      }

      th:last-child {
        border-right: 2px solid #ebe9f1;
        border-radius: 4px;
      }
    }

    td:first-child {
      border-left: 2px solid #ebe9f1;
    }
    td:last-child {
      border-right: 2px solid #ebe9f1;
    }

    tbody {
      tr:last-child {
        border-bottom: 2px solid #ebe9f1;
      }
    }

  }

  @media print {
    #app {
      * {
        visibility: hidden;
        margin: 0 !important;
      }

      #pdfDom,
      #pdfDom * {
        visibility: visible;
      }

      #pdfDom {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        margin-top: 100px;
      }
    }
  }

  @page {
    size: auto;
  }
</style>
